import "./App.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Home from "./pages/Home";
import Upload from "./pages/Upload";
import Requirements from "./pages/Requirements"
import Audit from "./pages/Audit";
import History from "./pages/History";
import Plan from "./pages/Plan";

import About from "./pages/About";
import Audit_plan from "./pages/Audit plan";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/home" element={<Home />}/>
        <Route path="/upload" element={<Upload />}/>
        <Route path="/requirements" element={<Requirements />}/>
        <Route path="/audit" element={<Audit />}/>
        <Route path="/history" element={<History />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/about" element={<About />}/>
        {/* <Route path="/search" element={<Search />}/> */}
        <Route path="/audit_plan" element={<Audit_plan />}/>
      </Routes>
    </Router>
  )
}
// const App = () => {
//   const propsData = {
//     component6: {
//       hoursRequirement: "Machine Learning",
//       frame: frame,
//     },
//     component3: {
//       next: "Next",
//     },
//   };
//   return (
//     <div className="front-frame">
//       <Sidebar />
//       <div className="frame-10">
//         <img className="graduation-cap-svgre" src={graduationCapSvgre} />
//         <span className="cmuda">CMUDA</span>
//         <span className="columbia-mscs-unoffi">
//           Columbia MSCS Unofficial Degree Audit{" "}
//         </span>
//         <span className="note-this-is-an-unof">
//           note: this is an unoffical report for planning purposes, please check
//           with your advisor for final confirmation.{" "}
//         </span>
//         <span className="to-run-the-mscs-degr">
//           To run the MSCS degree audit, first choose your track
//         </span>
//         <Component1
//           className="component-6-instance-1"
//           {...propsData.component6}
//         />
//         <Component2
//           className="component-3-instance-1"
//           {...propsData.component3}
//         />
//       </div>
//     </div>
//   );
// };
export default App;