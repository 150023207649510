import "../stylesheets/SpecShort.css"
function SpecShort(props){
    return(
        <div className="main_container2">
        <div className="left2">
            <div className={`stats_s2_${props.stats_short}`}> {props.stats_short} </div>
        </div>
        <div className="right2">
            <div className="title_s2">
                {props.spec_title_short}
            </div>
        </div>
    </div>
    )
}

export default SpecShort