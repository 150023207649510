import React, { useState } from "react";
import SidebarMenu from "../components/SidebarMenu";
import AuditItem from "../components/AuditItem";
import "../stylesheets/About.css";
import "../App.css";
import columbia from "../assets/Columbia_Crown_simple.svg"
import checkmark from "../assets/checkmark.svg"
import { useMediaQuery } from 'react-responsive'


//import { useMediaQuery } from "react";

const About = () => {
    const isMobile = useMediaQuery({ query: '(min-width:600px)' });
    // typo: use `=` instead of `:`
    // dont use setState in constructor, initialize state instead
    //const [isMobile] = useMediaQuery("(max-width: 768px)");

    
    return (
        <div className="main_frame">
            
            <SidebarMenu/>
            <div className="right_frame_about">
                <div className="frame_about1">
                    <div className="flex-row">
                        <h1 className="title">
                            About this Project
                        </h1>
                        
                        

                        

                    </div>
                    <div>Originally a COMS4705W: USER INTERFACE DESIGN Class Project<br/>
                        Developed by Zhaoyuan Deng and Xinyue Zheng <br/>
                        Advised by Professor Brian Smith and TA Sofia Bianchi
                    </div>
                    <div> <br/>
                        
                        <a href="https://shrub-preface-458.notion.site/Columbia-MSCS-Unofficial-Degree-Audit-82ea0ccbe12f4c2ca5149b803879b4a3">Known issues and future plans</a> <br/>
                        Contact: zd2286@columbia.edu 
                    </div>
                    
                </div>
                <div className="frame_about2">
                <div className="flex-center-bottom">
                <img className="columbia" src={columbia} />
                    </div>
                       
                    
                </div>
                
            </div>
          
        </div>
    )
};

export default About