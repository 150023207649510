import React, { useState } from "react";
import SidebarMenu from "../components/SidebarMenu";
import AuditItem from "../components/AuditItem";
import "../stylesheets/Audit.css";
import "../App.css";
import error from "../assets/error-warning-fill.svg"

const Audit_plan = () => {
    
    const array = [1,2,3]
    const requirements = [
        {stats:"OK",spec_title: "Theory", spec_details:`All COMS 42xx courses plus COMS W4995 – “Advanced Algorithms”,\n “Randomized Algorithms” and CSOR 4231 (not CSOR 4246)`,requirements_short:[{stats_short:"OK",spec_title_short:"CSOR 4231: Analysis of Algorithms I"}] },
        {stats:"OK",spec_title: "Systems", spec_details:`All COMS 41xx courses plus COMS W4995 “Engineering Software as a Service” except COMS 4121, COMS 416x and COMS 417x \n All COMS 48xx courses`,requirements_short:[{stats_short:"OK",spec_title_short:"COMS 4186: Malware Analysis & Reverse Enginnering"}]},
        
        {stats:"OK",spec_title: "AI&Applications", spec_details:`All COMS 47xx courses except COMS 4721 and COMS 4776 \n All COMS 416x and COMS 417x`,requirements_short:[{stats_short:"OK",spec_title_short:"COMS 4774: Unsupervised Learning"}]}
    ]

    const track_requirements = [
        {stats:"OK",spec_title: "REQUIRED TRACK COURSES", spec_details:`Two courses from group A, or one course from group A plus one course from group B`,
        requirements_short:
        [{stats_short:"OK",spec_title_short:`Group A \n COMS W4252 : Introduction to Computational Learning Theory`},
        {stats_short:"OK",spec_title_short:`Group B \n COMS W4277 : Introduction to  Dummy Data`}
    ] },
        {stats:"OK",spec_title: "ELECTIVE TRACK COURSES", spec_details:`Two courses from elective list, at least one of which must be a 6000-level course, cannot double count from required track courses`,
        requirements_short:
        [{stats_short:"OK",spec_title_short:`one course from electives \n COMS W4252 : Introduction to Computational Learning Theory`},
        {stats_short:"OK",spec_title_short:`one course from electives that is 6000-level \n COMS W6666 : Introduction to Dummy Data II`}
    ]},
    ]

    const degree_requirements =[
        {stats:"OK",spec_title: "Complete a total of 30 points", spec_details:`Earned: 30 \n In Progress: 0 \n Need: 0`,
        requirements_short:[]},
        {stats:"OK",spec_title: "At least 2.7 overall GPA", spec_details:`Earned: 3.84`,
        requirements_short:[]},
        {stats:"OK",spec_title: "PDL requirement", spec_details:`Earned: P`,
        requirements_short:[]}
    ]
    
    return (
        <div className="main_frame">
            
            <SidebarMenu/>
            <div className="right_frame">
            <div className="frame-14">
                <div className="flex-row">
                    <h1 className="title">
                        MSCS Degree Audit
                    </h1>
                    <div className="c">
                        <div className="edit">
                            Edit Courses
                        </div>
                        <div className="choose">
                            Choose different track
                        </div>

                    </div>

                </div>
                <div className="flex-container-1">
                    <div className="rectangle-9">
                        <div className="rectangle-82">Machine Learning Track</div>
                        <div className="date-prepared-110622">
                            Date Prepared: 11&#x2F;06&#x2F;22 - 11:18
                        </div>
                        
                        <div className="catalog-year-2021">
                            Catalog Year: 2021
                                
                        </div>
                        <div className="unoffical">
                        note: this is an unoffical report for planning purposes, please
                                check with your advisor for final confirmation.
                        </div>
                        
                    </div>
            
                    <div className="cat-absolute-container2">
                            <div className="error_icon">
                                <img className="error" src={error} />
                            </div>
                            
                            <div className="at-least-one-require" onClick={() => {window.location.pathname ="/audit"}}>
                            Degree Audit with Proposed Plan.  Click to Switch to Actual Courses 
                            </div>
                    </div>
                </div>
                <div className="flex-container-main">
                    <AuditItem 
                    data="OK" className="green" name="Breadth Requirements" spec_title="Systems" spec_details="a"
                    spec_title_short="COMS 4170 Malware Analysis And Reverse Engineering" requirements={requirements}/>
                    <AuditItem 
                    data="OK" className="green" name="Degree Requirements" requirements={degree_requirements}/>

                    <AuditItem 
                    data="OK" className="green" name="Track Requirements" requirements={track_requirements}/>
                    <div className="bottom">
                    _
                </div>
                </div>
                </div>
                
            </div>
        </div>
    )
};

export default Audit_plan