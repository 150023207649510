import React from "react";
import "../App.css";
import "../stylesheets/Requirements.css";
import breadthBlock from "../assets/breadthBlock.svg";
import requiredBlock from "../assets/requiredBlock.svg";
import electiveBlock from "../assets/electiveBlock.svg";
import generalBlock from "../assets/generalBlock.svg";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";

import SidebarMenu from "../components/SidebarMenu";

const Requirements = () => {
  const str = 'Here is what MSCS Machine Learning Track Requires: \n where each rectangle represents 3 points';
  let navigate = useNavigate();
  return (
    <div className="front-frame">
      <SidebarMenu />
      <div className="frame-5">
        <p className="guide1">        
          {str}
        </p>
        <Container>
          <Row>
            <Col xs={3}></Col>
            <Col xs={3}>breadth: </Col>
            <Col xs={1}><img src={breadthBlock} alt="" /></Col>
            <Col xs={1}><img src={breadthBlock} alt="" /></Col>
            <Col xs={1}><img src={breadthBlock} alt="" /></Col>
            <Col xs={3}></Col>
          </Row>
          <Row>
            <Col xs={3}></Col>
            <Col xs={3}>track required: </Col>
            <Col xs={1}><img src={requiredBlock} alt="" /></Col>
            <Col xs={1}><img src={requiredBlock} alt="" /></Col>
            <Col xs={1}><img src={requiredBlock} alt="" /></Col>
            <Col xs={3}></Col>
          </Row>
          <Row>
            <Col xs={3}></Col>
            <Col xs={3}>track elective: </Col>
            <Col xs={1}><img src={electiveBlock} alt="" /></Col>
            <Col xs={1}><img src={electiveBlock} alt="" /></Col>
            <Col xs={1}><img src={electiveBlock} alt="" /></Col>
            <Col xs={3}></Col>
          </Row>
          <Row>
            <Col xs={3}></Col>
            <Col xs={3}>general elective: </Col>
            <Col xs={1}><img src={generalBlock} alt="" /></Col>
            <Col xs={1}><img src={generalBlock} alt="" /></Col>
            <Col xs={1}><img src={generalBlock} alt="" /></Col>
            <Col xs={3}></Col>
          </Row>
          <Row>
            <Col xs={3}></Col>
            <Col xs={4}>*for any remaining points: </Col>
            <Col xs={1}><img src={generalBlock} alt="" /></Col>
            <Col xs={1}></Col>
            <Col xs={3}></Col>
          </Row>
        </Container>
        <span className="you-can-use-this-to">
          You can use this to get a quick glance of what’s left in your
          requirements.
        </span>
        <button className="component-3" onClick={() => {
              navigate("/history")
          }}>Next</button>
      </div>
    </div>
  );
};

export default Requirements