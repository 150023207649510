import "../stylesheets/Course_mini.css";
import Tags from "./Tags";
const CourseMini = (props) => {
    const course_info = props.course_info
    const results = [];
    course_info.forEach((i) => {
        if (i!=null){
        results.push(
            <Tags name={i} className={i}/>
            
        )}
        ;
    });
    return(
        <div className="course_frame_mini">
            <div className="main_course_frame_mini">

            
            <div className="course_title_mini">
                {props.course_title}
            </div>
            <div className="course_name">
                {props.course_name}
            </div>
            
            <div className="tags">
                {results}
            </div>
            
            </div>
            
        </div>
    )
};

export default CourseMini