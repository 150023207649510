import React from "react";
import "../App.css";
import "../stylesheets/Plan.css";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClassCard from "../components/ClassCard";

import breadthC from '../assets/breadthC.svg';
import groupA from '../assets/groupA.svg';
import elective from '../assets/elective.svg';
import trackElective from '../assets/trackElective.svg';
import breadthA from '../assets/breadthA.svg';
import breadthB from '../assets/breadthB.svg';
import CourseMini from "../components/Course_mini";

import { useNavigate } from "react-router-dom";

import SidebarMenu from "../components/SidebarMenu";

function Plan() {
    let navigate = useNavigate();
    //const results = [];
    //const courses = {1: {'COMS4186W': [null, true, true, 1]}};
    
    const results = [];

    const courses2 = {1: {'COMS4186W': ['Breadth 3','Track A', 'Elective', null]},
   
   3: {'COMS4170W': ['Breadth 1', 'Track B', 'true', 3],
    'COMS6901E': ['breadth', 'breath', 'true', false],
    'ENGI4990E': [null, 'breath', 'true', false]},
    4: {'COMS4170W': ['breadth', 'breath', 'true', 3],
    'COMS6901E': ['breadth', 'breath', 'true', false],
    'ENGI4990E': ['breadth', 'breath', 'true', false]}};

    const courses = JSON.parse(sessionStorage.getItem('results'))['tags'];
  
        
    
    return (
        <div className="front-frame">
            <SidebarMenu />
            <div className="main-frame">
                <div className="right-frame-plan">
                    <Container className="big-container-plan" fluid>
                        <Row className="row-1">
                            
                            {Object.keys(courses).map((key) => {
                                
                                return(
                                    <Col xs ={12} md={4} sm={6} className="col-1">
                                <Row>
                                <Col md={12} className="sem-title">Semester {key}</Col>
                                    
                                    {Object.keys(courses[key]).map((key2) => {
                                        
                                        console.log(courses[key][key2])
                                        const array_2 = courses[key][key2].slice(1);

                                        console.log(array_2)
                                        return(<Col md={12}><CourseMini select_or_not="notpicked" course_title={key2} course_name={courses[key][key2][0]} course_instructor="Carl Vondrick" 
                                        course_info=
                                        {array_2}/></Col>);
                                        
                                    })}
                                </Row>
                            </Col>
                                
                                );

                            })}
                           
                        </Row>
                    </Container>
                    
                    
                </div>
                {/* <div className="conclusion">
                        Remaining Requirements:
                       
                    </div> */}
                {/* <div className="two-buttons">
                        <button className="plan-button"onClick={() => {navigate("/plan")}}>{"Export Plan"}</button>
                        <button className="plan-button" onClick={() => {navigate("/audit_plan")}}> {"Audit with Plan"}</button>
                </div> */}
                <div className="buttom-row">

                </div>
            </div>
        </div>
    );
}

export default Plan;