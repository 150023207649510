//*Dropzone.js*//
//
import React ,{useCallback} from "react";
import { useDropzone } from "react-dropzone";
import frame from "../assets/fileIcon.svg";
import axios from "axios";
//const storage = $.sessionStorage;

//import "./index.css";

function Dropzone({ open }) {
  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles[0]);
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    axios.post(`https://cu-degree-audit.com:8000/process`, formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res => {
      console.log(res);
      console.log(res.data);
      sessionStorage.setItem("results", JSON.stringify(res.data.result));
      //const myresult = sessionStorage.getItem('results')
      console.log(JSON.parse(sessionStorage.getItem('results')));
      window.location.pathname = "/history"
    })
    
    }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  return (
    <div className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <img className="frame" src={frame} alt=""/>
        <p className="drag">Drop the .html here</p>
      </div>
      <aside>
        
      </aside>
    </div>
  );
}
export default Dropzone;