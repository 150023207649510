import "../stylesheets/SpecLong.css";
import SpecShort from "./SpecShort"
function SpecLong(props){
    const requirements_short = props.requirements_short
    const results = [];
    requirements_short.forEach((requirements_short, index) => {
        results.push(
            <SpecShort stats_short={requirements_short.stats_short} spec_title_short={requirements_short.spec_title_short}/>,
        )
        ;
    });
    return(
        <div className="main_container">
            <div className="left">
                <div className={`stats_s_${props.stats}`}> {props.stats} </div>
            </div>
            <div className="right">
                <div className="title_s">
                    <div>
                {props.spec_title}
                </div>
                
                <div className="details">
                {props.spec_details}
                </div>
                </div>
            {results}
            </div>
        </div>
    )
}

export default SpecLong