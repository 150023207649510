import React, { useState } from "react";
import SidebarMenu from "../components/SidebarMenu";
import AuditItem from "../components/AuditItem";
import "../stylesheets/Audit.css";
import "../App.css";
import error from "../assets/error-warning-fill.svg"
import checkmark from "../assets/checkmark.svg"
import { useMediaQuery } from 'react-responsive'


//import { useMediaQuery } from "react";

const Audit = () => {
    const isMobile = useMediaQuery({ query: '(min-width:600px)' });
    // typo: use `=` instead of `:`
    // dont use setState in constructor, initialize state instead
    //const [isMobile] = useMediaQuery("(max-width: 768px)");
    const results = JSON.parse(sessionStorage.getItem('results'));
    const breadth_requirements = results['breadth'];
    const track_requirements = results['track'];
    const degree_requirements = results['degree_message'];
    const track_status = results['track_status']
    const breadth_status = results['breadth_status']
    const degree_status = results['degree_status']
    const overall_status = results['overall_status']
    var degree_status_short =''
    var breadth_status_short =''
    var track_status_short= ''
    if (degree_status == 'Fulfilled'){
        degree_status_short = 'OK'
    }else{
        degree_status_short = 'NO'
    };
    if (track_status == 'Fulfilled'){
        track_status_short = 'OK'
    }else{
        track_status_short = 'NO'
    };
    if (breadth_status == 'Fulfilled'){
        breadth_status_short = 'OK'
    }else{
        breadth_status_short = 'NO'
    };
    const today = new Date();
    
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const breadth_requirements2 = [
        {stats:"OK",spec_title: "Systems", spec_details:`All COMS 41xx courses plus COMS W4995 “Engineering Software as a Service” except COMS 4121, COMS 416x and COMS 417x \n All COMS 48xx courses`,requirements_short:[{stats_short:"OK",spec_title_short:"COMS 4186: Malware Analysis & Reverse Enginnering"}]},
        {stats:"IP",spec_title: "Theory", spec_details:`All COMS 42xx courses plus COMS W4995 – “Advanced Algorithms”,\n “Randomized Algorithms” and CSOR 4231 (not CSOR 4246)`,requirements_short:[{stats_short:"IP",spec_title_short:"CSOR 4231: Analysis of Algorithms I"}] },
        {stats:"OK",spec_title: "AI&Applications", spec_details:`All COMS 47xx courses except COMS 4721 and COMS 4776 \n All COMS 416x and COMS 417x`,requirements_short:[{stats_short:"OK",spec_title_short:"COMS 4774: Unsupervised Learning"}]},
        {stats:"OK",spec_title: "Systems, Theory, or AI&Applications", spec_details:`One more course from any of the three categories `,requirements_short:[{stats_short:"OK",spec_title_short:"COMS 4774: Unsupervised Learning"}]}
    ]

    const track_requirements2 = [
        {stats:"NO",spec_title: "REQUIRED TRACK COURSES", spec_details:`Two courses from group A, or one course from group A plus one course from group B`,
        requirements_short:
        [{stats_short:"OK",spec_title_short:`Group A \n COMS W4252 : Introduction to Computational Learning Theory`},
        {stats_short:"NO",spec_title_short:<div onClick={() => {window.location.pathname ="/search"}}>You need one course from <span className="link_color">group A or one course from group B</span></div>}
    ] },
        {stats:"OK",spec_title: "ELECTIVE TRACK COURSES", spec_details:`Two courses from elective list, at least one of which must be a 6000-level course, cannot double count from required track courses`,
        requirements_short:
        [{stats_short:"OK",spec_title_short:`one course from electives \n COMS W4252 : Introduction to Computational Learning Theory`},
        {stats_short:"NO",spec_title_short:<div onClick={() => {window.location.pathname ="/search"}}>You need one course from <span className="link_color">electives that is 6000-level</span></div>}
    ]},
    ]

    const degree_requirements2 =[
        {stats:"NO",spec_title: "Complete a total of 30 points", spec_details:`Earned: 12 \n In Progress: 12 \n Need: 6`,
        requirements_short:[]},
        {stats:"OK",spec_title: "At least 2.7 overall GPA", spec_details:`Earned: 3.84`,
        requirements_short:[]},
        {stats:"OK",spec_title: "PDL requirement", spec_details:`Earned: P`,
        requirements_short:[]}
    ]
    
    return (
        <div className="main_frame">
            
            <SidebarMenu/>
            <div className="right_frame">
            <div className="frame-14">
                <div className="flex-row">
                    <h1 className="title">
                        MSCS Degree Audit
                    </h1>
                    <div className="edit"onClick={() => {window.location.pathname ="/home"}}>
                        {/* <div className="edit" onClick={() => {window.location.pathname ="/upload"}}> */}
                            Restart 
                        {/* </div> */}
                        {/* <div className="choose" onClick={() => {window.location.pathname ="/home"}}>
                            Choose different track
                        </div> */}

                    </div>

                </div>
                <div className="flex-container-1">
                    <div className="rectangle-9">
                        <div className="rectangle-8">Machine Learning Track</div>
                        <div className="date-prepared-110622">
                            Date Prepared: {`${year}-${month}-${day}`}
                        </div>
                        
                        {/* <div className="catalog-year-2021">
                            Catalog Year: 2021
                                
                        </div> */}
                        <div className="unoffical">
                        note: this is an unoffical report for planning purposes, please
                                check with your advisor for final confirmation.
                        </div>
                        
                    </div>
            
                    <div className={`overall_container${overall_status}`}>
                            <div className="error_icon">
                                <img className="error" src={(() => {
        if (overall_status=="All requirements completed, congrats!") {
          return (
            checkmark
          )
        } else {
          return (
            error
          )
        }
      })()} />
                            </div>
                            
                            <div className={`overall`}>
                                {overall_status}
                            </div>
                    </div>
                </div>
                <div className="flex-container-main">
                   
                    <AuditItem 
                    data={isMobile ? `${breadth_status}` : `${breadth_status_short}`} className={breadth_status} name="Breadth Requirements" requirements={breadth_requirements}/>
                    <AuditItem 
                    data={isMobile ? `${degree_status}` : `${degree_status_short}`} className={degree_status} name="Degree Requirements" requirements={degree_requirements}/>

                    <AuditItem 
                    data={isMobile ? `${track_status}` : `${track_status_short}`} className={track_status} name="Track Requirements" requirements={track_requirements}/>
                    <div className="bottom">
                    _
                </div>
                </div>
                </div>
                
            </div>
        </div>
    )
};

export default Audit