import React from "react";

import "../App.css";
import "../stylesheets/Upload.css"
import graduationCapSvgre from "../assets/graduationCapSvgre.svg";
import vector from "../assets/questionMark.svg";
import ManualImport from "../components/ManualImport";
import Dropzone from "../components/Dropzone";
import { Link } from "react-router-dom";
import { useState } from "react";
import SidebarMenu from "../components/SidebarMenu";

const Upload = () => {
    const [buttonPopup, setButtonPopup] = useState(false);
    return (
        <div className="front-frame">
            
            <div className="frame-10">
                <img className="graduation-cap-svgre" src={graduationCapSvgre} alt=""/>
                {/* <span className="cmuda">CMUDA</span> */}
                <span className="columbia-mscs-unoffi">
                    Columbia MSCS Unofficial Degree Audit{" "}
                </span>
                <span className="note-this-is-an-unof">
                    note: this is an unoffical report for planning purposes, please check
                    with your advisor for final confirmation.{" "}
                </span>
                <div className="flex-container">
                    <span className="then-download-your-d">
                    then, download "Enrollment and Program summary" Page as html from vergil.columbia.edu
                    and upload the html file here{" "}
                    </span>
                    <div onClick={() => setButtonPopup(true)}><img className="vector" src={vector} alt=""/>
                    
                    </div>
                    
                </div>
                <div className="rectangle-7">
                    
                    <span className="drag-drop-your-file">
                    <Dropzone />
                    </span>
                </div>
                {/* <span className="or-manually-input-yo" onClick={() => {window.location.pathname ="/requirements"}}>
                    Or, manually input your courses
                </span> */}
                <span className="examplefile" >
                    or, try with one of the example files:  <Link to="/record_demo1.html" target="_blank" download>example1</Link>, <Link to="/record_demo2.html" target="_blank" download>example2</Link>, <Link to="/record_demo3.html" target="_blank" download>example3</Link>

                </span>
               
               
                <ManualImport trigger={buttonPopup} setTrigger={setButtonPopup}/>
                
            </div>
        </div>
    );
};

export default Upload