import React from 'react'
import useCollapse from 'react-collapsed'
import arrow_down from '../assets/arrow-down.svg'
import arrow_left from '../assets/arrow-left.svg'
import "../stylesheets/AuditItem.css";
import SpecLong from './SpecLong';
function AuditItem(props) {
    const requirements = props.requirements
    console.log({requirements})
    const results = [];
    requirements.forEach((requirements, index) => {
        results.push(
            <SpecLong stats={requirements.stats} spec_title={requirements.spec_title} spec_details={requirements.spec_details} requirements_short={requirements.requirements_short}/>,
        )
        ;
    });
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
    return (
        <div className="collapsible">
        <div className="flex-container-item" {...getToggleProps()}>
            <div className={`stats-${props.className}`}> {props.data} </div>
            <div> {props.name}</div>
            
            {isExpanded ? <img className="c_icon" src={arrow_down}></img> : <img className="c_icon" src={arrow_left}></img>}
        </div>
        <div {...getCollapseProps()}>
            <div className="content">
            {results}
            </div>
            
        </div>
    </div>
         
    )
}


export default AuditItem