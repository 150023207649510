import "../stylesheets/Component2.css";
import { useNavigate } from "react-router-dom";

const Component2 = (props) => {
  let navigate = useNavigate();
  return (
    <div className={`component-2 ${props.className || ""}`}>
      <span className="next" onClick={() => {
              navigate("/upload")
          }}>{props.next || "Next"}</span>
    </div>
  );
};
export default Component2;