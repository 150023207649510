import React from 'react'
import { Link } from "react-router-dom";
import "../stylesheets/Menu.css"
import { Sidebar, Menu, MenuItem,sidebarClasses } from 'react-pro-sidebar';
import NavComponent from "./NavComponent";
import auditHome from "../assets/auditHome.svg";
import myPlan from "../assets/myplan.svg";
import searchCourses from "../assets/searchCourses.svg";
import about from "../assets/about.svg";

const SidebarMenu = () => {
    return (
        
        <Sidebar 
        rootStyles={{
            [`.${sidebarClasses.container}`]: {
              position: 'fixed',
              width: "65px"
            },
          }}
          width="65px">
            <Menu menuItemStyles={{
                button: () => {
                    return {
                        height: "80px",
                        fontSize: "13px",
                        lineHeight: "14px",
                        textAlign: "center",
                        paddingRight: "0px",
                        paddingLeft: "0px",
                        paddingTop: "15px",
                    };
                },
            }} className="outer-menu">
                <MenuItem className="menu" routerLink={<Link to="/audit" />}> {<NavComponent className="t" title={"Audit"} icon={auditHome} />}</MenuItem>
                <MenuItem className="menu" routerLink={<Link to="/plan" />}> {<NavComponent title={"Courses"} icon={myPlan} />}</MenuItem>
                <MenuItem className="menu" routerLink={<Link to="/about" />}> {<NavComponent title={"About"} icon={about} />}</MenuItem>
                {/* <MenuItem className="menu" routerLink={<Link to="/search" />}> {<NavComponent title={"Search Courses"} icon={searchCourses} />}</MenuItem> */}
            </Menu>
        </Sidebar>
        
    );
}

export default SidebarMenu;