import React, { useState } from "react";

import Component2 from "../components/Component2";
import frame from "../assets/frame.svg";
import graduationCapSvgre from "../assets/graduationCapSvgre.svg";
import "../App.css";
import "../stylesheets/Home.css";
import Select from 'react-select';

//import Dropdown_menu from '../components/Dropdown_menu';

import SidebarMenu from "../components/SidebarMenu";

const Home = () => {
  const propsData = {
    component6: {
      hoursRequirement: "Machine Learning",
      frame: frame,
    },
    component3: {
      next: "Next",
    },
  };
  const options = [
    { value: 'machine Learning', label: 'Machine Learning'},
    // { value: 'computational biology', label: 'Computational Biology' },
    // { value: 'computer security', label: 'Computer Security' },
    // { value: 'natural language processing', label: 'Natural Language Processing' },
    // { value: 'network systems', label: 'Network Systems' },
    // { value: 'software systems', label: 'Software Systems' },
    // { value: 'vision, graphics, interaction, and robotics', label: 'Vision, Graphics, Interaction, and Robotics' }
  ];
  const Drop_menu = () => (
    <Select 
    className="drop_menu"
    defaultValue={options[0]}
    options={options}
    
     />
  )
  
  return (
    <div className="front-frame">
      
      <div className="frame-10">
        <img className="graduation-cap-svgre" src={graduationCapSvgre} alt=""/>
        {/* <span className="cmuda">CMUDA</span> */}
        <span className="columbia-mscs-unoffi">
          Columbia MSCS Unofficial Degree Audit{" "}
        </span>
        <span className="note-this-is-an-unof">
          note: this is an unoffical report for planning purposes, please check
          with your advisor for final confirmation.{" "}
        </span>
        <span className="alphawarning">
        Caution: This is an alpha version and is still undergoing development.{" "}
        </span>
        <span className="to-run-the-mscs-degr">
          To run the MSCS degree audit, first choose your track 
        </span>
        <span className="only">
          *only Machine Learning Track is supported currently
        </span>
        <Drop_menu
        />
      
        <Component2
          className="component-3-instance-1"
          {...propsData.component3}
        />
      </div>
    </div>
  );
};

export default Home