import React from 'react'
import "../stylesheets/ManualImport.css"

function ManualImport(props) {
    return (props.trigger) ? (
        <div className="frame-17">
            <div className="frame-17-inner">
                <button className="close-btn" onClick={() => props.setTrigger(false)}>x</button>
                <span className="instructions">
                    Having trouble downloading the html page? <br /><br />
                    1. Go to vergil.columbia.edu <br />
                    2. Login with your uni and password <br />
                    3. Click “Enrollment and Program summary” under "Academic Record" under "Student Record"<br />
                    4. Right-click and click "Save as..."<br /> 
                    5. Upload the saved file here<br />
                </span>
            </div>
        </div>
    ) : "";
}

export default ManualImport


