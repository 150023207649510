import React from "react";
import "../stylesheets/NavComponent.css";

const NavComponent = (props) => {
    return (
        <div className="small-component">
            <img src={props.icon} alt=""/>
            <p> {props.title}</p>
        </div>
    );
}

export default NavComponent;